<template>
  <PageWithLayout>
    <TableView>
      <template v-slot:tbl_colgroup>
        <col style="width:130px"/>
        <col/>
      </template>
      <template v-slot:tbl_top>
        <div class="area_left">
          <strong class="tit_tbl">공지사항 {{ viewModel.isModify ? '수정' : '등록' }}</strong>
        </div>
      </template>
      <template v-slot:tbl_body>
        <tr>
          <th>고정</th>
          <td>
            <SwitchCheck :value.sync="viewModel.model.isFixed" text="고정" offText="미고정" />
          </td>
        </tr>
        <tr>
          <th>제목<IconSvg iconName="essential" :size="6" iconColor="#F04848"/></th>
          <td>
            <Input
              placeholder="공지사항 제목을 입력해주세요."
              :value.sync="viewModel.model.title"/>
          </td>
        </tr>
        <tr>
          <th>내용<IconSvg iconName="essential" :size="6" iconColor="#F04848"/></th>
          <td>
            <Textarea
              placeholder="공지사항 내용을 입력해주세요."
              :value.sync="viewModel.model.content"
              :isCount="true"/>
          </td>
        </tr>
      </template>
    </TableView>
    <Sticky>
      <div class="area_left">
        <Button
          btnSize="medium"
          btnStyle="secondary_border"
          text="취소"
          :routeName="viewModel.isModify ? 'APP_NOTICE_DETAIL' : 'APP_NOTICE_LIST'"/>
      </div>
      <div class="area_right">
        <Button
          v-if="viewModel.isModify"
          btnSize="medium"
          btnStyle="fourthly"
          text="삭제"
          @onClickBtn="viewModel.onClickDelete()"/>
        <Button
          btnSize="medium"
          btnStyle="primary"
          text="저장"
          @onClickBtn="viewModel.onClickComplete()"/>
      </div>
    </Sticky>
  </PageWithLayout>
</template>

<script>
import PageWithLayout from '@lemontree-ai/lemontree-admin-common-front/components/layout/PageWithLayout';
import TableView from '@lemontree-ai/lemontree-admin-common-front/components/common/table/TableView';
import IconSvg from '@lemontree-ai/lemontree-admin-common-front/components/common/icon/IconSvg';
import SwitchCheck from '@lemontree-ai/lemontree-admin-common-front/components/common/switch/SwitchCheck';
import Input from '@lemontree-ai/lemontree-admin-common-front/components/common/input/Input';
import Textarea from '@lemontree-ai/lemontree-admin-common-front/components/common/textarea/Textarea';
import Sticky from '@lemontree-ai/lemontree-admin-common-front/components/common/sticky/Sticky';
import Button from '@lemontree-ai/lemontree-admin-common-front/components/common/button/Button';
// viewModel
import NoticeDetailViewModel from '@/views/landing/notice/viewModel/NoticeDetailViewModel'

export default {
  name:'NoticeRegister',
  components:{
    PageWithLayout,
    TableView,
    IconSvg,
    SwitchCheck,
    Input,
    Textarea,
    Sticky,
    Button,
  },
  beforeMount(){
    const id = this.$route.params.id;
    this.viewModel.init(id);
    this.viewModel.isModify = this.$route.meta.isModify;
  },
  data(){
    return{
      viewModel: new NoticeDetailViewModel(),
    }
  },
}
</script>